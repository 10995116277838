$ubiquity-font-value: var(--primary-font), sans-serif;
$ubiquity-font-noto: 'notosans', sans-serif;
$ecc-icon-font: 'ecc-icons';

// --------- Style Guide Colors ------------------------

$ampli-primary: var(--color-primary);
$ampli-primary-link-hover: var(--color-primary-hover);
$ampli-error: var(--color-error);
$ampli-secondary-1: var(--color-secondary-1);
$ampli-secondary-2: var(--color-secondary-2);
$ampli-secondary-3: var(--color-secondary-3);
$ampli-secondary-4: var(--color-secondary-4);
$ampli-secondary-5: var(--color-secondary-5);
$ampli-secondary-6: var(--color-secondary-6);
$ampli-secondary-7: var(--color-secondary-7);
$ampli-secondary-8: var(--color-secondary-8);
$ampli-ui-1: var(--color-ui-1);
$ampli-ui-2: var(--color-ui-2);
$ampli-ui-3: var(--color-ui-3);
$ampli-tag-1: var(--color-tag-1);
$ampli-tag-2: var(--color-tag-2);
$ampli-tag-3: var(--color-tag-3);
$ampli-tag-4: var(--color-tag-4);
$ampli-tag-5: var(--color-tag-5);

// ------------------------------------------------------
$ampli-silver: #e6e7e9;
$ampli-white: #fff;
$ampli-grey: #ccc;
$ampli-grey: #444;
$ampli-black: #222;
$ampli-smoke: #888;

// Spacing
$page-margin: 20px;
$grid-gap: 20px;

//Video
$video-height-l: 600px;
$minimized-video-height: 170px;

$userListHeaderHeight: 90px;


// Breakpoint
$break-medium: 1024px;
$break-large: 1280px;
$break-small-height: 800px;

@mixin word-wrap() {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
