@import "icons/style";

$ecc-font-value-path: "/assets/fonts/value";
$ecc-font-notosans-path: "/assets/fonts/notosans";

@font-face {
  font-family: 'notosans';
  src: url('#{$ecc-font-notosans-path}/NotoSans.eot');
  src: url('#{$ecc-font-notosans-path}/NotoSans.eot?#iefix') format('embedded-opentype'),
  url('#{$ecc-font-notosans-path}/NotoSans.woff') format('woff'),
  url('#{$ecc-font-notosans-path}/NotoSans.ttf') format('truetype'),
  url('#{$ecc-font-notosans-path}/NotoSans.svg#NotoSans') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'notosans';
  src: url('#{$ecc-font-notosans-path}/NotoSans-Bold.eot');
  src: url('#{$ecc-font-notosans-path}/NotoSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$ecc-font-notosans-path}/NotoSans-Bold.woff') format('woff'),
  url('#{$ecc-font-notosans-path}/NotoSans-Bold.ttf') format('truetype'),
  url('#{$ecc-font-notosans-path}/NotoSans-Bold.svg#NotoSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'notosans';
  src: url('#{$ecc-font-notosans-path}/NotoSans-BoldItalic.eot');
  src: url('#{$ecc-font-notosans-path}/NotoSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$ecc-font-notosans-path}/NotoSans-BoldItalic.woff') format('woff'),
  url('#{$ecc-font-notosans-path}/NotoSans-BoldItalic.ttf') format('truetype'),
  url('#{$ecc-font-notosans-path}/NotoSans-BoldItalic.svg#NotoSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'notosans';
  src: url('#{$ecc-font-notosans-path}/NotoSans-Italic.eot');
  src: url('#{$ecc-font-notosans-path}/NotoSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('#{$ecc-font-notosans-path}/NotoSans-Italic.woff') format('woff'),
  url('#{$ecc-font-notosans-path}/NotoSans-Italic.ttf') format('truetype'),
  url('#{$ecc-font-notosans-path}/NotoSans-Italic.svg#NotoSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'value';
  src: url('#{$ecc-font-value-path}/Value.eot');
  src: url('#{$ecc-font-value-path}/Value.eot?#iefix') format('embedded-opentype'),
  url('#{$ecc-font-value-path}/Value.woff') format('woff'),
  url('#{$ecc-font-value-path}/Value.ttf') format('truetype'),
  url('#{$ecc-font-value-path}/Value.svg#Value') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'value';
  src: url('#{$ecc-font-value-path}/Value-Bold.eot');
  src: url('#{$ecc-font-value-path}/Value-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$ecc-font-value-path}/Value-Bold.woff') format('woff'),
  url('#{$ecc-font-value-path}/Value-Bold.ttf') format('truetype'),
  url('#{$ecc-font-value-path}/Value-Bold.svg#Value-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
