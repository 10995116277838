@use '@angular/material' as mat;
@import 'variables';
@import "../app/core/components/footer/footer-contacts.tooltip";

@include mat.core();

$md-mcgpalette-primary: (
  50 : #f8e0e8,
  100 : #eeb3c5,
  200 : #e2809f,
  300 : #d64d78,
  400 : #ce265b,
  500 : #c5003e,
  600 : #bf0038,
  700 : #b80030,
  800 : #b00028,
  900 : #a3001b,
  A100 : #ffced3,
  A200 : #ff9ba5,
  A400 : #ff6877,
  A700 : #ff4e60,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-mcgpalette-secondary: (
  50 : #f9e8ec,
  100 : #f1c5cf,
  200 : #e89eaf,
  300 : #df778e,
  400 : #d85976,
  500 : #d13c5e,
  600 : #cc3656,
  700 : #c62e4c,
  800 : #c02742,
  900 : #b51a31,
  A100 : #ffeaec,
  A200 : #ffb7c0,
  A400 : #ff8493,
  A700 : #ff6a7d,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$mat-ubiquity-primary: mat.define-palette($md-mcgpalette-primary, 500);
$mat-ubiquity-secondary: mat.define-palette($md-mcgpalette-secondary, 500);

$mat-ubiquity-typography: mat.define-typography-config(
  $font-family: $ubiquity-font-noto,
);

$mat-ubiquity-theme: mat.define-light-theme((
  color: (
    primary: $mat-ubiquity-primary,
    accent: $mat-ubiquity-secondary,
  ),
  density: 0,
  typography: $mat-ubiquity-typography
));

@include mat.all-component-themes($mat-ubiquity-theme);

.material-overrides {

  mat-form-field {
    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
    }
  }

  // Material input
  .mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: none;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    align-items: center;
    height: 50px;
    margin-top: 0;
    padding: 0 20px;
    border: 2px solid $ampli-silver;
    border-radius: 25px;
    background-color: $ampli-white;
    cursor: pointer;
  }

  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0;
  }

  .mat-form-field-infix {
    margin-top: -2px;
    border-top: 0;
    height: 50px;
    width: 300px;
  }

  // Material date range
  .mat-date-range-input-start-wrapper {
    flex: 1;
    text-align: right;
  }

  .mat-date-range-input-end-wrapper {
    flex: 1;
    text-align: left;
  }

  // Material table
  table.mat-table {
    width: 100%;
  }

  .mat-header-cell, .mat-cell {
    padding: 20px;
  }

  .mat-date-range-input-inner::-webkit-input-placeholder, .mat-date-range-input-separator {
    transition: none;
    color: #888;
  }
}
