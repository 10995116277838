$icomoon-font-family: "ecc-icons" !default;
$icomoon-font-path: "/assets/fonts/icons/fonts" !default;

$ecc-icon-recall: "\e936";
$ecc-icon-missed-call: "\e935";
$ecc-icon-clock: "\e933";
$ecc-icon-va-check: "\e934";
$ecc-icon-va-client: "\e932";
$ecc-icon-va-cc: "\e938";
$ecc-icon-va-audio: "\e939";
$ecc-icon-va-camera: "\e93a";
$ecc-icon-rating-empty: "\e930";
$ecc-icon-rating-full: "\e931";
$ecc-icon-attachment: "\e92f";
$ecc-icon-pause: "\e92d";
$ecc-icon-play: "\e92e";
$ecc-icon-calendar: "\e92c";
$ecc-icon-send: "\e926";
$ecc-icon-settings: "\e929";
$ecc-icon-volume: "\e92b";
$ecc-icon-chat: "\e925";
$ecc-icon-phone: "\e927";
$ecc-icon-videocall: "\e928";
$ecc-icon-microphone: "\e92a";
$ecc-icon-onboarding: "\e924";
$ecc-icon-info: "\e923";
$ecc-icon-icon-read: "\e921";
$ecc-icon-icon-unread: "\e922";
$ecc-icon-icon-unsnooze: "\e91e";
$ecc-icon-icon-snooze: "\e91f";
$ecc-icon-icon-check: "\e920";
$ecc-icon-icon-menu: "\e91d";
$ecc-icon-graph-alert-1-active-path1: "\e919";
$ecc-icon-graph-alert-1-active-path2: "\e91b";
$ecc-icon-graph-alert-1-secondary-path1: "\e917";
$ecc-icon-graph-alert-1-secondary-path2: "\e918";
$ecc-icon-legend-average-accurate: "\e914";
$ecc-icon-legend-target: "\e911";
$ecc-icon-legend-split-l-accurate: "\e915";
$ecc-icon-legend-r-accurate: "\e916";
$ecc-icon-alert1: "\e912";
$ecc-icon-arrow-right: "\e90c";
$ecc-icon-arrow-left: "\e90d";
$ecc-icon-data-accurate: "\e90e";
$ecc-icon-data-estimated: "\e90f";
$ecc-icon-data-none: "\e913";
$ecc-icon-unstar: "\e90a";
$ecc-icon-star: "\e91c";
$ecc-icon-dash: "\e909";
$ecc-icon-chevron-right: "\e901";
$ecc-icon-chevron-down: "\e90b";
$ecc-icon-chevron-up: "\e910";
$ecc-icon-new-customers: "\e904";
$ecc-icon-clients-in-trial: "\e905";
$ecc-icon-all-clients: "\e906";
$ecc-icon-plus: "\e907";
$ecc-icon-alerts: "\e900";
$ecc-icon-alert: "\e908";
$ecc-icon-search: "\e902";
$ecc-icon-delete: "\e903";
$ecc-icon-add: "\e91a";
