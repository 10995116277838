@import '~swiper/swiper-bundle.css';
@import './scss/font';
@import './scss/variables';
@import './scss/position-helpers';
@import './scss/typography';
@import './scss/cta';
@import './scss/auth';
@import './icons/variables';
@import './icons/style';
@import './scss/mat';

:root {
  --primary-font: 'value';
  --color-primary: #C5003E;
  --color-primary-hover: #D13C5E;
  --color-error: #C5003E;
  --color-secondary-1: #2E3342;
  --color-secondary-2: #545D78;
  --color-secondary-3: #8E919C;
  --color-secondary-4: #E4E6EB;
  --color-secondary-5: #F5F6FA;
  --color-secondary-6: #FAFBFD;
  --color-secondary-7: #F7F8F8;
  --color-secondary-8: #008540;
  --color-ui-1: #479D1A;
  --color-ui-2: #F3B229;
  --color-ui-3: #E15D3D;
  --color-tag-1: #A97C1C;
  --color-tag-2: #244D6F;
  --color-tag-3: #7A4661;
  --color-tag-4: #29708b;
  --color-tag-5: #7D6147;
  --navbar-logo-path: url('/assets/images/logo.png');
  --footer-logo-path: url('/assets/images/logo-footer.png');
}

*, ::after, ::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  &:focus {
    outline: none;
  }
}

::selection {
  background: $ampli-primary;
  color: $ampli-white;
}

::-moz-selection {
  background: $ampli-primary;
  color: $ampli-white;
}

html, body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: $ubiquity-font-value;
  -webkit-font-smoothing: antialiased;
  color: $ampli-secondary-1;
  font-weight: normal;
  background-color: $ampli-secondary-6;
}

form {
  display: table;
  width: 100%;

  .form-group {
    margin-bottom: 25px;
  }
}

.page {
  min-height: calc(100vh - 70px);
  padding-top: 70px;

  &.basic {
    padding: 130px 40px;
  }
}

.pac-container {
  background-color: $ampli-white;
  border: 2px solid $ampli-silver;
  border-top: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: none;
  font-family: $ubiquity-font-noto;
  padding: 10px;
  min-width: 510px;

  &.pac-logo {
    &:after {
      background-position: 95%;
      background-size: 143px 18px;
      height: 22px;
      margin-bottom: 20px;
      margin-top: 5px;
    }
  }

  .pac-item {
    border: none;
    color: #999;
    cursor: pointer;
    font-size: 11px;
    line-height: 30px;
    overflow: hidden;
    padding: 5px 25px 5px 4px;
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: $ampli-primary;

      &::after {
        color: $ampli-black;
        content: $ecc-icon-chevron-right;
        position: absolute;
        font-size: 22px;
        font-family: $icomoon-font-family;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .pac-item-query {
        color: $ampli-primary;

        .pac-matched {
          color: $ampli-primary;
        }
      }
    }

    .pac-icon-marker {
      display: none;
    }

    .pac-item-query {
      font-size: 16px;
      color: $ampli-smoke;

      .pac-matched {
        font-size: 16px;
        color: #444;
      }

      +span {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
