@import "variables";

.auth {
  background-color: $ampli-silver;
  display: flex;
  justify-content: center;

  &--box {
    margin-top: 60px;
    width: 400px;
    text-align: center;

    h2 {
      margin-bottom: 55px;
    }

    .checkbox {
      text-align: left;
      padding: 0 20px;
      margin-bottom: 40px;
    }

    .forgot-pwd {
      margin-top: 30px;

      p {
        margin-bottom: 10px;
      }
    }

    .errors {
      margin-top: 30px;
      color: $ampli-primary;
    }
  }
}
