.p-0 {
  padding: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.m-t-15 {
  margin-top: 15px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-30 {
  margin-right: 30px;
}

.mr-2 {
  margin-right: 20px;
}

.mr-3 {
  margin-right: 30px;
}

.mb-25 {
  margin-bottom: 25px;
}
