@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?90aohb') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?90aohb') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?90aohb##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;

}

[class^="ecc-icon-"], [class*=" ecc-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ecc-icon-recall {
  &:before {
    content: $ecc-icon-recall;
  }
}
.ecc-icon-missed-call {
  &:before {
    content: $ecc-icon-missed-call;
  }
}
.ecc-icon-clock {
  &:before {
    content: $ecc-icon-clock;
  }
}
.ecc-icon-va-check {
  &:before {
    content: $ecc-icon-va-check;
  }
}
.ecc-icon-va-client {
  &:before {
    content: $ecc-icon-va-client;
  }
}
.ecc-icon-va-cc {
  &:before {
    content: $ecc-icon-va-cc;
  }
}
.ecc-icon-va-audio {
  &:before {
    content: $ecc-icon-va-audio;
  }
}
.ecc-icon-va-camera {
  &:before {
    content: $ecc-icon-va-camera;
  }
}
.ecc-icon-rating-empty {
  &:before {
    content: $ecc-icon-rating-empty;
  }
}
.ecc-icon-rating-full {
  &:before {
    content: $ecc-icon-rating-full;
  }
}
.ecc-icon-attachment {
  &:before {
    content: $ecc-icon-attachment;
  }
}
.ecc-icon-pause {
  &:before {
    content: $ecc-icon-pause;
  }
}
.ecc-icon-play {
  &:before {
    content: $ecc-icon-play;
  }
}
.ecc-icon-calendar {
  &:before {
    content: $ecc-icon-calendar;
  }
}
.ecc-icon-send {
  &:before {
    content: $ecc-icon-send;
  }
}
.ecc-icon-settings {
  &:before {
    content: $ecc-icon-settings;
  }
}
.ecc-icon-volume {
  &:before {
    content: $ecc-icon-volume;
  }
}
.ecc-icon-chat {
  &:before {
    content: $ecc-icon-chat;
  }
}
.ecc-icon-phone {
  &:before {
    content: $ecc-icon-phone;
  }
}
.ecc-icon-videocall {
  &:before {
    content: $ecc-icon-videocall;
  }
}
.ecc-icon-microphone {
  &:before {
    content: $ecc-icon-microphone;
  }
}
.ecc-icon-onboarding {
  &:before {
    content: $ecc-icon-onboarding;
  }
}
.ecc-icon-info {
  &:before {
    content: $ecc-icon-info;
  }
}
.ecc-icon-icon-read {
  &:before {
    content: $ecc-icon-icon-read;
  }
}
.ecc-icon-icon-unread {
  &:before {
    content: $ecc-icon-icon-unread;
  }
}
.ecc-icon-icon-unsnooze {
  &:before {
    content: $ecc-icon-icon-unsnooze;
  }
}
.ecc-icon-icon-snooze {
  &:before {
    content: $ecc-icon-icon-snooze;
  }
}
.ecc-icon-icon-check {
  &:before {
    content: $ecc-icon-icon-check;
  }
}
.ecc-icon-icon-menu {
  &:before {
    content: $ecc-icon-icon-menu;
  }
}
.ecc-icon-graph-alert-1-active .path1 {
  &:before {
    content: $ecc-icon-graph-alert-1-active-path1;
    color: rgb(36, 77, 111);
    opacity: 0.3;
  }
}
.ecc-icon-graph-alert-1-active .path2 {
  &:before {
    content: $ecc-icon-graph-alert-1-active-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.ecc-icon-graph-alert-1-secondary .path1 {
  &:before {
    content: $ecc-icon-graph-alert-1-secondary-path1;
    color: rgb(189, 201, 212);
  }
}
.ecc-icon-graph-alert-1-secondary .path2 {
  &:before {
    content: $ecc-icon-graph-alert-1-secondary-path2;
    margin-left: -1em;
    color: rgb(36, 77, 111);
  }
}
.ecc-icon-legend-average-accurate {
  &:before {
    content: $ecc-icon-legend-average-accurate;
  }
}
.ecc-icon-legend-target {
  &:before {
    content: $ecc-icon-legend-target;
  }
}
.ecc-icon-legend-split-l-accurate {
  &:before {
    content: $ecc-icon-legend-split-l-accurate;
  }
}
.ecc-icon-legend-r-accurate {
  &:before {
    content: $ecc-icon-legend-r-accurate;
  }
}
.ecc-icon-alert1 {
  &:before {
    content: $ecc-icon-alert1;
  }
}
.ecc-icon-arrow-right {
  &:before {
    content: $ecc-icon-arrow-right;
  }
}
.ecc-icon-arrow-left {
  &:before {
    content: $ecc-icon-arrow-left;
  }
}
.ecc-icon-data-accurate {
  &:before {
    content: $ecc-icon-data-accurate;
  }
}
.ecc-icon-data-estimated {
  &:before {
    content: $ecc-icon-data-estimated;
  }
}
.ecc-icon-data-none {
  &:before {
    content: $ecc-icon-data-none;
  }
}
.ecc-icon-unstar {
  &:before {
    content: $ecc-icon-unstar;
  }
}
.ecc-icon-star {
  &:before {
    content: $ecc-icon-star;
  }
}
.ecc-icon-dash {
  &:before {
    content: $ecc-icon-dash;
  }
}
.ecc-icon-chevron-right {
  &:before {
    content: $ecc-icon-chevron-right;
  }
}
.ecc-icon-chevron-down {
  &:before {
    content: $ecc-icon-chevron-down;
  }
}
.ecc-icon-chevron-up {
  &:before {
    content: $ecc-icon-chevron-up;
  }
}
.ecc-icon-new-customers {
  &:before {
    content: $ecc-icon-new-customers;
  }
}
.ecc-icon-clients-in-trial {
  &:before {
    content: $ecc-icon-clients-in-trial;
  }
}
.ecc-icon-all-clients {
  &:before {
    content: $ecc-icon-all-clients;
  }
}
.ecc-icon-plus {
  &:before {
    content: $ecc-icon-plus;
  }
}
.ecc-icon-alerts {
  &:before {
    content: $ecc-icon-alerts;
  }
}
.ecc-icon-alert {
  &:before {
    content: $ecc-icon-alert;
  }
}
.ecc-icon-search {
  &:before {
    content: $ecc-icon-search;
  }
}
.ecc-icon-delete {
  &:before {
    content: $ecc-icon-delete;
  }
}
.ecc-icon-add {
  &:before {
    content: $ecc-icon-add;
  }
}
