@import './variables';

h1 {
  font-family: $ubiquity-font-value;
  font-size: 40px;
  line-height: 55px;
  font-weight: bold;
  color: $ampli-secondary-1;
  margin: 0;
}

h2 {
  font-family: $ubiquity-font-value;
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  margin: 0;
}
h3 {
  font-family: $ubiquity-font-value;
  font-size: 25px;
  line-height: 35px;
  font-weight: bold;
  margin: 0;
}

h4 {
  font-family: $ubiquity-font-value;
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}

h5 {
  font-family: $ubiquity-font-value;
  font-size: 18px;
  line-height: 25px;
  font-weight: bold;
}

h6 {
  font-family: $ubiquity-font-value;
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  margin: 0;
}

@mixin body {
  font-family: $ubiquity-font-noto;
  font-size: 13px;
  line-height: 20px;
}
.body {
  @include body;
}

@mixin value-s {
  font-family: $ubiquity-font-value;
  font-size: 13px;
  line-height: 15px;
}
.value-s {
  @include value-s;

  &-b {
    font-weight: bold;
  }
}

p {
  margin: 0;
  font-family: $ubiquity-font-noto;
  font-size: 16px;
  line-height: 26px;
}

b {
  font-weight: bold;
}
