@use "sass:map";
@import "./typography";

@mixin cta-colors($font-color, $border-color, $bg-color) {
  color: $font-color;
  border-color: $border-color;
  background: $bg-color;
}

@mixin cta($conf: ()) {
  // Extending the default arguments with the given object
  $conf: map.merge(
      (border: true, decoration: none, hoverDecoration: none, padding: true, font: $ubiquity-font-value, weight: bold), $conf);
  $normal: map-get($conf, normal);
  $hover: map-get($conf, hover);
  $disabled: map-get($conf, disabled);
  $border: map-get($conf, border);
  $padding: map-get($conf, padding);
  $font: map.get($conf, font);
  $weight: map.get($conf, weight);

  // Dumping CSS
  .#{map-get($conf, name)} {
    font-family: $font;
    font-size: 16px;
    line-height: 16px;
    font-weight: $weight;
    text-decoration: map-get($conf, decoration);
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    @if ($padding) {
      padding: 10px 20px;
    }

    @if $border {
      border-radius: 50px;
      border: 2px solid;
    }

    @include cta-colors($normal...);

    &:focus {
      outline: none;
    }

    &:hover:not(.disabled) {
      @include cta-colors($hover...);
      text-decoration: map-get($conf, hoverDecoration);
    }

    &:disabled, &.disabled {
      @include cta-colors($disabled...);
      cursor: auto;

      em, i {
        cursor: auto;
      }
    }

    em, i {
      font-size: 16px;

      &:before {
        display: inline-block;
      }
    }

    .left {
      margin-right: 10px;
    }

    .right {
      margin-left: 10px;
    }
  }
}

// Including components
@include cta((
  name: 'cta-primary',
  normal: ($ampli-white, $ampli-primary, $ampli-primary),
  hover: ($ampli-white, $ampli-primary-link-hover, $ampli-primary-link-hover),
  disabled: ($ampli-white, $ampli-secondary-3, $ampli-secondary-3)
));

@include cta((
  name: 'cta-secondary',
  normal: ($ampli-secondary-1, $ampli-secondary-1, none),
  hover: ($ampli-primary, $ampli-primary, none),
  disabled: ($ampli-secondary-3, $ampli-secondary-3, none)
));

@include cta((
  name: 'cta-primary-on-dark',
  normal: ($ampli-white, $ampli-primary, $ampli-primary),
  hover: ($ampli-white, $ampli-primary-link-hover, $ampli-primary-link-hover),
  disabled: ($ampli-secondary-3, $ampli-secondary-4, $ampli-secondary-4)
));

@include cta((
  name: 'cta-secondary-on-dark',
  normal: ($ampli-white, $ampli-white, none),
  hover: ($ampli-white, $ampli-white, rgba(255, 255, 255, 0.15)),
  disabled: ($ampli-secondary-3, $ampli-secondary-3, none)
));

@include cta((
  name: 'cta-icon',
  normal: ($ampli-secondary-1, $ampli-secondary-1, none),
  hover: ($ampli-primary, $ampli-primary, none),
  disabled: ($ampli-secondary-3, $ampli-secondary-3, none)
));

@include cta((
  name: 'text-link',
  normal: ($ampli-primary, none, none),
  hover: ($ampli-primary-link-hover, none, none),
  disabled: ($ampli-secondary-3, none, none),
  border: false,
  padding: false,
  decoration: underline,
  hoverDecoration: underline
));

@include cta((
  name: 'text-link-grey',
  normal: ($ampli-smoke, none, none),
  hover: ($ampli-smoke, none, none),
  disabled: ($ampli-secondary-3, none, none),
  border: false,
  padding: false,
  decoration: underline,
  hoverDecoration: underline,
  font: $ubiquity-font-noto,
  weight: normal
));

@include cta((
  name: 'text-link-dark',
  normal: ($ampli-secondary-1, none, none),
  hover: ($ampli-primary, none, none),
  disabled: ($ampli-secondary-3, none, none),
  border: false,
  padding: false,
  decoration: underline,
  hoverDecoration: underline
));

@include cta((
  name: 'text-link-on-dark',
  normal: ($ampli-white, none, none),
  hover: (rgba(255, 255, 255, 0.8), none, none),
  disabled: ($ampli-secondary-3, none, none),
  border: false,
  padding: false,
  decoration: underline,
  hoverDecoration: underline
));

@include cta((
  name: 'text-link-icon',
  normal: ($ampli-primary, none, none),
  hover: ($ampli-primary-link-hover, none, none),
  disabled: ($ampli-secondary-3, none, none),
  border: false,
  padding: false,
  hoverDecoration: underline
));

@include cta((
  name: 'text-link-icon-secondary',
  normal: ($ampli-secondary-1, none, none),
  hover: ($ampli-primary, none, none),
  disabled: ($ampli-secondary-3, none, none),
  border: false,
  padding: false,
  hoverDecoration: underline
));

@include cta((
  name: 'text-link-icon-on-dark',
  normal: ($ampli-white, none, none),
  hover: ($ampli-white, none, none),
  disabled: ($ampli-secondary-3, none, none),
  border: false,
  padding: false,
  hoverDecoration: underline
));

@include cta((
  name: 'accordion-btn',
  normal: ($ampli-secondary-1, none, none),
  hover: ($ampli-primary, none, none),
  disabled: ($ampli-secondary-3, none, none),
  border: false,
  padding: false,
));
